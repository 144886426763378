// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require_tree .

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch'
import 'element-closest-polyfill'
import 'focus-visible/dist/focus-visible.min.js'
import 'waypoints/lib/noframework.waypoints.min.js'
// import "core-js/modules/es7.promise.finally";
import CustomEvent from 'custom-event-polyfill';

// Object-fit & object-position support for older browsers
import objectFitImages from 'object-fit-images';
objectFitImages('img', {watchMQ: true});

// smooth scroll to anchor links
import SmoothScroll from 'smooth-scroll'
const scroll = new SmoothScroll('a.scrollto', {
  speed: 500,
  speedAsDuration: true,
  easing: 'easeInOutCubic'
});

// ------------------

// Gather all js files from components folders


  import '/sccr/app/components/learning_zone/anchor_navigation/anchor_navigation.js';

  import '/sccr/app/components/learning_zone/accordion/accordion.js';

  import '/sccr/app/components/learning_zone/carousel/carousel.js';

  import '/sccr/app/components/tabs/tabs.js';

  import '/sccr/app/components/radial_stat/stat.js';

  import '/sccr/app/components/core/hero/resource/hero_component.js';

  import '/sccr/app/components/core/header/navigation/navigation.js';

  import '/sccr/app/components/accordion/accordion_component.js';

  import '/sccr/app/components/homepage_main_navigation_component/homepage_main_navigation_component.js';

  import '/sccr/app/components/history/history.js';

  import '/sccr/app/components/highlights/highlights.js';

  import '/sccr/app/components/testimonial_carousel/carousel.js';

  import '/sccr/app/components/homunculus/carousel/carousel.js';

  import '/sccr/app/components/homunculus/drugs_cabinet/drugs_cabinet.js';


