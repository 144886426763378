// import * as u from 'JS/utils'

document.addEventListener("turbolinks:load", function() {
  const wrapper = document.getElementById('anchor-nav-wraper')
  const anchors = document.querySelectorAll("[data-anchor]")

  if(anchors.length > 0) {
    const link = document.createElement('a')
    link.classList.add('scrollto')
    link.classList.add('learning-zone__anchor-navigation-link')
    
    anchors.forEach((anchor, index) => {
      const anchorId = anchor.getAttribute('id')
      if (!anchorId) return
      const anchorText = anchor.getAttribute('data-anchor-label')
      const anchorLink = link.cloneNode()
      anchorLink.setAttribute('href', `#${anchorId}`)
      anchorLink.innerHTML = anchorText
      wrapper.appendChild(anchorLink)
    })
  }
});
